.dashboard-widget {
  flex-grow: 1;

  overflow: hidden;

  box-shadow: 0px 0px 4px 0px $widget-shadow-color;
  background-color: $table-background-color;
  border-radius: 5px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    outline: 1px $widget-outline-color dashed;
  }
}

.widget-dropdown-content {
  margin-top: -1px;
  box-shadow: 0px 0px 2px 0px $widget-dropdown-shadow;
  font-family: $ui-font;
  font-size: $widget-body-font-size;
  background-color: $background-light;
  color: black;

  hr {
    margin: 0;
    border-top-width: 2px;
  }

  div {
    padding: 5px 10px;

    &:hover {
      cursor: pointer;
      background-color: dds("700");
      color: white;
    }

    &[disabled] {
      background-color: $button-disabled-background;
      color: dds-greys("100");
    }
  }

  i {
    margin-right: 6px;
  }
}

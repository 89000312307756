.map-legend-filter-icon {
}

.map-legend-filter-icon img {
  object-fit: scale-down;
  width: 28px;
  height: 20px;
}

.legend-checkbox {
  padding-left: 20px;
}

.routes .map-legend-filter-icon img {
  object-fit: scale-down;
  width: 154px;
  height: 21px;
}

.navigation-mode.notification-bar{
  z-index: 1001;
}

.notification-bar{
  .alert {
    top:50px;
  }
}
.notification-bar {
  z-index: zindex(action-modal);

  .alert {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 24px;
    margin: 0;
    border: 0;
    border-radius: 0;
  }

  .alert-icon {
    margin-right: 16px;
    display: block;
  }

  .alert-close {
    margin-left: auto;
    background: none;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
  }

  .alert-text {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
  }

  .alert-info {
    background-color: $info-dds;
  }

  .alert-warning {
    background-color: $warning-dds;
  }

  .alert-emergency {
    background-color: $error-dds;
  }

  .alert-success {
    background-color: $success-dds;
  }
}

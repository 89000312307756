.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center {
  .tooltip-modal-styles-overlay {
        padding: 0;
        border-radius: 1;
        z-index: zindex(action-modal);
        outline: none;
        &.ember-modal-overlay.translucent {
          background-color: rgba(38,50,56,0.2);
        }
      
      .ember-modal-dialog {
          background-color: transparent;
          box-shadow: none;
          transform: none !important;
          left: calc(50% - 200px);
          top: calc(50% - 90px);
          z-index: zindex(action-modal);

        .tooltip-modal {
          width: 400px;
          height: 180px;
          // height: 552px;
          color: white;
          background: #263238;
          border-radius: 10px;
          box-shadow: 1px 2px 6px 1px rgba(0,0,0,0.4);
      
          .tooltip-modal-header {
            width: 100%;
            height: 35px;
            background-image: linear-gradient( #334247, #171f22);
            font-size: medium;
            text-align: center;
            line-height: 2;
            padding-left: 6px;
            border-radius: 10px 10px 0px 0px;
      
            button {
              background-color: transparent;
              border: none;
              outline: none;
              float: right;
              padding-right: 15px;
              color: #ffffff;

              &:hover {
                color: $dds-primary;
              }
            }
          }
      
          .tooltip-modal-body {
            margin: 0 15px;
            padding-top: 17px;
            font-size: small;
            font-weight: 100;
            .status {
              width: 42px;
              height: 42px;
              margin-right: 8px;
              float: left;
      
              .warning-icon {
                width: 100%;
                height: 100%;
                background-image: url('/icons/ic-new-warning.svg');
                background-repeat: no-repeat;
              }
            }
            .tooltip-modal-button{
              margin-top: 15px;
              text-align: center;
              position: absolute;
              left: 182px;
              top: 130px;
              .btn-outline-secondary, .btn-outline-primary {
                color: white;
                border-color: white;
                line-height: 1.0;
                padding: 5px 20px;
                margin-right: 10px;
              }

              .tooltip-running {
                min-width: 98px;
              }
            }
          }
        }
      }
  }
}

.tab-group {
  display: flex;
  @include box-shadow(0, 2px, 4px,rgba(199,199,199,0.5));
  height: 40px;
  position: relative;

  &::after {
    border-width: 0;
  }
  &::before {
    border-width: 0;
  }

  .tab-button {
    @include tab-button(black, $light-dds, $dds-primary, 0);
    font-size: 13px;
    font-weight: 700;
  }
}
// secondary menu (workspace navigation) background colors
$workspace-menu-background-light: $background-lighter;
$workspace-menu-background-dark: black;


.workspace-nav-wrapper {
  @include box-shadow(0, 0, 8px,rgba(0, 0, 0, 0.5 ));
  flex: 0 0 $workspace-nav-height;
  z-index: zindex(header);

  .workspace-navbar {

    display: flex;
    height: $workspace-nav-height;
    justify-content: space-between;
    align-items: center;
    background-color: $background-lighter;
    color: dds-greys("800");
    padding: 5px 0 5px 20px;
    font-weight: 500;
    font-size: 14px;

    &.reoptimize-in-progress {
      background-color: $info-dds;
      color: $white;
    }

    .workspace-logo {
      padding-right: 4rem;

      .fa {
        color: #a7b3b9;
        font-weight: normal;
      }
      .fa-th:before {
        content: "\f00a";
      }
    }

    .datetimeflatpickr-wrapper {
      .flatpickr-input {
        width: 100px;
        height: 22px;
        font-size: 12px;
        border: solid 1px rgba(38, 50, 56, 0.2);
      }
      i {
        right: 15%;
        cursor: pointer;
      }
    }

    .re-optimize-btn {
      @include button;
      height: 22px;
      font-size: 12px;
      font-weight: 500;
    }

    #header-left {
      display: flex;
      align-items: baseline;
      flex: 1 0 auto;
    }
    #header-center {
      display: flex;
      align-content: center;
      justify-content: flex-start;
      flex: 0 1 60%;
      padding: 0px 30px;
      text-transform: uppercase;
      p {
        margin: 0 10px;
        padding: 0;
        flex: 0 0 auto;
      }

      .progress-window {
        flex: 0 1 50%;
        padding: 0;
        margin: 0;
        /* align-self: baseline; */
        display: flex;
        flex-direction: row;
        align-self: center;

        .progress {
          display: flex;
          height: 10px;
          overflow: hidden;
          background-color: #e9ecef;
          border-radius: 0.25rem;
          width: 100%;
        }
      }
      

    }
    #header-right {
      display: flex;
      flex: 1 0 5%;
      padding: 0px 30px;
    }
    
  }

}

.disable-pointer-events .re-optimize-btn {
  pointer-events: none;
}
.disable-pointer-events .workspace-navbar {
  background-color: #1366A5;
  color: #ffffff;
}


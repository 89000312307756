.btn {
  &.btn-outline-primary-simple {
    color: dds("700");
    border-color: dds("700");
    &:hover {
      background-color: dds("700");
      color: white;
    }
    &:focus {
      box-shadow: none;
    }

    &:active {
      background-color: dds("700");
      color: white;
    }
  }

  &.btn-primary-simple {
    color: white;
    background-color: $dds-primary;
    border-color: $dds-primary;
    &:hover {
      background-color: dds("700");
    }

    &:focus {
      box-shadow: none;
    }

    &:active {
      background-color: dds("700");
    }
  }
}
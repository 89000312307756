$add-widget-button-color: dds-greys("900");
// side-drawer table colors
$add-widget-table-background-color: $background-lighter;
$add-widget-table-border-color: #CCCED0;

@mixin widget-icon($icon-name) {
  display: block;
  background-color: $background-white;
  width: 50px;
  height: 50px;
  margin: 5px auto;
  background-size: 25px 25px;


  img {
    width: 100%;
    height: 100%;
    @include transition($transition-base-dds);

    -webkit-mask: url('/widget_icons/#{$icon-name}.svg') no-repeat 50% 50%;
    mask: url('/widget_icons/#{$icon-name}.svg') no-repeat 50% 50%;

    background-color: $dds-primary-grey;
  }
}

.add-widget-drawer {
  right: calc(100% - 300px);
}

.add-widget {
  .g-side-drawer-body {
    padding: 8px 5px;
    background-color: $background-white;
  }
  .add-widget-body {

  }
}

.add-widget-section {
  width: 100%;
  min-width: 190px;
  padding: 5px;
  margin-bottom: 10px;
  background-color: $add-widget-table-background-color;
  border: 1px solid $add-widget-table-border-color;
  border-radius: 3px;

  display: inline-block;

  .add-widget-section-header {
    cursor: pointer;
    .add-widget-section-title {
      font-size: $widget-input-font-size;
      font-weight: 800;
      vertical-align: middle;
      padding: 12px 3px 10px 8px;

      line-height: 1em;
      margin-bottom: 0px;
    }

    .fa-sort-down {
      margin-right: 3px;
      transform: rotate(0deg);
      transition: all .1s linear;
      // width: 1rem;
      // height: 1rem;

      &:before {
        position: relative;
        top: -.23rem;
      }
    }

    &.is-closed {
      .fa-sort-down {
        transform: rotate(-90deg);
      }
    }

  }

  .add-widget-section-body {
    display: flex;
    justify-content: flex-start;
    flex-shrink: 1;
    flex-grow: 1;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 10px;

    .add-widget-button {
      text-align: center;
      flex-basis: 75px;
      margin: 5px;
      float: left;
      display: block;
      padding: 0.2em;
      height: 6em;
      border: none;
      line-height: 1.2em;
      text-decoration: none;
      font-size: $widget-input-font-size;
      color: $add-widget-button-color;
      text-overflow: ellipsis;
      background-color: inherit;
      outline: none;

      cursor: pointer;


      p {
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 500;
      }

      .widget-icon {
        border: 1px solid $button-outline;
        border-radius: 3px;
        padding: .5em;
        margin-bottom: 8px;
        font-size: 100%;
      }

      // Classes for widget icons
      // format is: .widget-type-{{widget.name}}
      .widget-type-messages {
        @include widget-icon('widget_messaging');
      }

      .widget-type-system {
        @include widget-icon('widget_system');
      }

      .widget-type-routes {
        @include widget-icon('widget_routes');
      }

      .widget-type-stops {
        @include widget-icon('widget_stops');
      }

      .widget-type-trips {
        @include widget-icon('widget_trips');
      }

      .widget-type-users {
        @include widget-icon('widget_users');
      }

      .widget-type-roles {
        @include widget-icon('widget_users');
      }

      .widget-type-subscriptions {
        @include widget-icon('widget_subscriptions');
      }

      .widget-type-map {
        @include widget-icon('widget_map');
      }

      .widget-type-passenger {
        @include widget-icon('widget_passengers');
      }

      .widget-type-drivers {
        @include widget-icon('widget_drivers');
      }

      .widget-type-alerts {
        @include widget-icon('widget_alerts');
      }

      .widget-type-vehicles {
        @include widget-icon('widget_vehicles');
      }

      .widget-type-zones {
        @include widget-icon('widget_zones');
      }

      .widget-type-box {
        @include widget-icon('widget_box');
      }

      .widget-type-metrics {
        @include widget-icon('widget_metrics');
      }

      .widget-type-donut-chart {
        @include widget-icon('widget_donut');
      }

      .widget-type-bar-chart {
        @include widget-icon('widget_bar');
      }

      .widget-type-gauge {
        @include widget-icon('widget_gauge');
      }
      .widget-type-scatter-chart {
        @include widget-icon('widget_metrics');
      }
      .widget-type-schedule {
        @include widget-icon('widget_schedule');
      }
      &:hover {
        .widget-icon {
          border-radius: 3px;
          border: 1px solid $dds-primary;

        }

        img {
          background-color: $dds-primary;
        }
      }

      &:active {
        .widget-icon {
          border-color: dds("700");
        }
        img {
          background-color: $dds-primary;
        }
      }

    }

  }
  .add-widget-table {
    // max-width: 300px;
    background-color: $add-widget-table-background-color;
    margin: 10px 0;
    border: 1px solid $add-widget-table-border-color;
    border-spacing: 0;
    border-collapse: separate;
    vertical-align: middle;
    // width: 100%;

    thead {
      user-select: none;
      cursor: pointer;
      vertical-align: middle;

    }
  }
  .add-widget-tbody {
    padding: 0;
    display: block;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .hidden {
    display: none;
    transition: max-height 0.2s ease-out;
  }


}

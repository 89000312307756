.compose-role-side-drawer {
  height: inherit;

  .g-side-drawer-sub-header {
    display: flex;
    align-content: baseline;
    align-items: baseline;

    span {
      flex: 1 1 30%;
    }

  }

  .g-side-drawer-body {
    padding-top: 8px;
    background-color: white;
    display: flex;
    flex-direction: row;
  }

  .role-row {
    display: flex;
    justify-content: flex-start;
    align-content: baseline;
    padding: 0px 10px 10px 10px;
    align-items: center;

    p {
      flex: 1 1 30%;
      margin: 0;
    }

    .role-name-input {
      flex: 1 1 70%;
    }

  }

  .permissions-table {
    padding: 0 10px 10px 10px;

    .permission-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .cell-label {
        font-size: 12px;
        order: 2;
        flex: 1 1 90%;
      }

      .permission-input {
        flex: 1 1 5%;      
      }

    }

  }
}
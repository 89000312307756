.locations-input {
  display: flex;
  .cell {

    &.edit {
      flex: 1 1 auto;
    }

    &.button {
      margin-left: 0.5rem;
      @include icon($dark-dds, transparent, #acb8bd);
      border-radius: 0.25rem;

      &:hover {
        color: $light-dds;
        background-color: $link-primary;
        transition: all 0.3s ease;
      }
    }
  }
}
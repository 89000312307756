.trip-transfer-side-drawer{
  height: inherit;
  overflow-y: auto;

  .trip-transfer-layout {
    &.auto-reschedule {
     .g-side-drawer-panel-title{
       margin-left: 10px;
     }
    }
    .g-side-drawer-body {
      margin-top: 8px;
      background-color: white;
      .g-side-drawer-scroll-container{
        &.g-schedule-trip-panel{
          height: auto;
        }
      }
      .g-side-drawer-panel.is-collapsible.is-expanded, .g-side-drawer-panel.is-initial{
        .g-side-drawer-panel-body {
          display: flex;
          flex-direction: column;
          padding: 0px 0 0px 0px;
          color: #303E44;

          .view-impact-table-wrapper {
            overflow: auto;
            flex-basis: 200px;
            flex-grow: 1;
            flex-shrink: 1;
            width: calc(100% - 0px);
            font-size: 12px;
            background-color: #fff;

            .ember-light-table {
              overflow: visible;
              font-feature-settings: "tnum";
              width: fit-content;
            }
            .yellow-table-row{
              background-color: #F0ED92 !important;
            }
          }

          .g-side-drawer-panel-selector {
            &.bulk-trip{
              float: left;
              width: 43%;
            }
            h5 {
              width: 66px;
              margin: 0px 14px 0 15px;
            }

            .dropdown {
              .ember-power-select-trigger {
                width: 155px;
              }
            }
          }

          input#override {
            margin-left: 10px;
          }

          label {
            margin: 5px 0px 7px 9px;
            font-size: 14px;
            font-weight: bold;
          }
          input[type="checkbox"] {
            vertical-align: bottom;
            position: relative;
            top: -4px;
            overflow: hidden;
          }
        }
      }
    }
     div.buttons{
      margin: 10px;
      display: flex;
      justify-content: space-around;
      .btn.schedule-btns{
        color: $btn-text-color;
        line-height: 9.6px;
        font-size: 14px;
        font-family: 'AvenirNext';
        border-color: $btn-border-color;
        background-image: $re-schedule-btn-bg-grey;
        border-radius: 2px;
      }
    }
  }
}
.bulk-transfer{
  hr{
    width: 94%;
    &:last-child{
      display: none;
    }
  }
}
.vertical-scroll{
  overflow-y: auto;
}

.solution-preview-header{
  background-color: white;
  border-bottom: 1px solid lightgray;
}

.g-side-drawer-panel p.trip-transfer-label {
  margin-top: 15px;
}

#login-template {
  margin: 0 auto;
  width: 50%;
  max-width: 500px;
  font-family: $ui-font;

  h1 {
    margin-top: 100px;
  }

  form {
    padding-top: 1rem;
    border-top: 1px solid rgb(238, 238, 238);
    text-align: right;

    label {
      width: 100%;
      text-align: left;

      input[type=text],
      input[type=email],
      input[type=password] {
        display: block;
        width: 100%;
        padding: 3px 7px;
        line-height: 1.5em;
        color: $input-color;
        background-color: $input-bg;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;
        border-radius: 3px;
        outline: none;
      }

      input[disabled] {
        background: #EFEFEF;
      }
    }

    .btn {
      line-height: 1.1;
      padding: .375rem 0.55rem;
      font-size: 15px;
      background-color: $light-dds;
      border: 1px solid $button-outline-primary;
      margin-left: .5rem;
      color: $button-link-color;

      &:disabled {
        color: $button-link-disabled-color;
        border: 1px solid $button-link-disabled-color;
        &:hover {
          cursor: not-allowed;
          background-color: $light-dds;
          border: 1px solid $button-link-disabled-color;
          color: $button-link-disabled-color;
        }
      }

      &:hover {
        background-color: $button-bg-primary;
        color: $button-link-alt-color;
        border: 1px solid $button-bg-primary;
      }
    }

    .errors {
      color: $light-dds;
      background-color: #ec7272;
      border-radius: 3px;
      margin: 1em;
      padding: 5px 3px;
      list-style: none;
      text-align: center;
    }
  }
}

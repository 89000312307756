.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center {
  .zone-modal-styles-overlay {
        padding: 0;
        border-radius: 1;
        z-index: zindex(action-modal);
        outline: none;
        &.ember-modal-overlay.translucent {
          background-color: rgba(38,50,56,0.2);
        }
      
      .ember-modal-dialog {
          background-color: transparent;
          box-shadow: none;
          z-index: zindex(action-modal);

        .zone-modal {
          background: $light-dds;
          border-radius: 10px;
          box-shadow: 1px 2px 6px 1px rgba(0,0,0,0.4);
      
          .zone-modal-header {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: $background-lighter;
            font-size: $font-size-base-dds;
            border-radius: 10px 10px 0 0;
            padding: 0.5rem;
            @include box-shadow(0, 2px, 4px, rgba(148,148,148,0.5));

            .table-component-content {
              z-index: calc(zindex(action-modal) + 1);
            }

            h5 {
              font-size: $font-size-base-dds;
              margin: 0;
              font-weight: $font-weight-bold-dds;
            }

            .zone-modal-header-buttons {
              display: flex;
              align-items: center;
              padding: 0 0.5rem 0 0.5rem;

              i {
                @include icon($dds-grey-200);
                margin-left: 0.5rem;
              }
            }
      

          }
      
          .zone-modal-body {
            padding: 1rem;
            font-size: small;
            font-weight: 100;

            .container {
              display: flex;

              .zone-button-container {
                display: flex;
                border-radius: 4px;
                justify-content: center;
                margin: 0 1rem 1rem 1rem;
                .button-group {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  flex: 0 0 15%;
  
                  button {
                    @include button();
                    width: 100px;
                    height: 25px;
                    border: 1px solid #B4B4B4;
                    border-radius: 2px;
                    background: $button-bg-grey;
                    margin-bottom: 1rem;
                    line-height: 1.1;
                    cursor: pointer;
                    
                
                    span {
                      flex: auto;
                      font-size: 14px;
                      font-weight: 500;
                      display: flex;
                      align-items: flex-end;

                      svg {
                        width: 15px;
                        height: 14px;
                      }

                    }
                  }
                }
              }
  
              .table {
                width: 250px;
                overflow: inherit;

                .table-component {
                  overflow: inherit;

                  .table-component-header {
                    background: $background-lighter;
                  }
                  
                  .lt-head-wrap {
                    thead th {
                      border-bottom: none;
                    }
                  }

                  .lt-head th input {
                    float: none;
                  }

                  .lt-body-wrap {
                    margin-top: 0;
                    .table-base-cell {
                      text-overflow: inherit;
                      overflow: unset;
                    }
                  }
                  .lt-cell {
                    &:focus-within {
                      background-color: inherit;
                    }
                  }
                }
              }

            }


          }
        }
      }
  }
}
/*
  DEFAULT MARKER CLUSTER CSS
*/

$cluster-bg-color: white;

.marker-cluster-small div {
  border: 1px solid black;
  background-color: $cluster-bg-color;
}

.marker-cluster-medium div {
  border: 1px solid black;
  background-color: $cluster-bg-color;
}

.marker-cluster-large div {
  border: 1px solid black;
  background-color: $cluster-bg-color;
}

.marker-cluster {
  background-clip: padding-box;
  background-color: transparent;
  border-radius: 20px;
}
.marker-cluster div {
  width: 23px;
  height: 23px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 23px;
}

/*
  DEFAULT LEAFLET MARKER CLUSTER Animation
*/
.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
	-webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
	-moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
	-o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
	transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
	/* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
	-webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
	-moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
	-o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
	transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}
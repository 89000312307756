.operation-view-wrapper {
  padding: 0 10px;
  overflow: hidden;

  .table-component {
    height: 490px;
  }

  .button-holder {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px 10px 10px;

    button {
      @include button;
      height: 26px;
      width: 101px;
      border: 1px solid #B4B4B4;
      border-radius: 2px;
      background: $button-bg-grey;
      display: flex;
      justify-content: flex-end;
  
      span {
        flex: auto;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

}
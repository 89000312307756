.route-activity-log {
  height: 100%;

  display: flex;
  flex-direction: column;

  .header-sub-header{
    width: 100%;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    button {
      width: 100%;
    }
  }

  .g-side-drawer-body {
    padding-left: 10px;
    padding-right: 10px;

    font-size: 12px;
    padding-top: 8px;


    .ember-light-table {
      flex: 1 0 100px;
    }

    .lt-row {
      &:nth-child(2n-1) {
        background-color: $table-row-alt-background-color;
      }

      &.is-selected {
        background-color: $table-row-selected-color !important;
      }
    }

    .lt-cell {
      vertical-align: top;
    }
  }
}

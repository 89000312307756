$side-drawer-modal-font-color: $dds-primary-grey;
$side-drawer-modal-background-color: white;

.side-drawer-modal {
  height: calc(100vh - #{$total-nav-height});
  border-radius: 0;
  box-shadow: 7px 0px 20px -10px rgba(0, 0, 0, .4);

  position: fixed;
  top: $total-nav-height;
  left: 0;
  right: calc(100% - 400px);
  min-width: 460px;

  background-color: $side-drawer-modal-background-color;
  color: $side-drawer-modal-font-color;
  font-family: $ui-font;

  padding: 0;
  z-index: 99;

  overflow-x: hidden;

  &.side-drawer-shrunken {
    height: calc(100vh - #{$total-nav-height});
    border-radius: 0;
    width: 42px;
    min-width: 42px;
    box-shadow: 7px 0px 20px -10px rgba(0, 0, 0, .4);
    background-color: $side-drawer-modal-background-color;
    padding: 0;

    position: fixed;
    top: $total-nav-height;
    left: 0;

    .side-drawer-buttons {
      padding-top: 10px;
      button {
        width: 100%;
        height: 100%;
        font-size: 12px;
        padding: 3px 5px;
        display: inline-block;
        background-color: inherit;
        border: none;
        color: $icon-light-color;
        opacity: 0.5;
        cursor: pointer;
        outline: none;

        &:hover {
          color: dds("700");
          transition: color 0.2s;
          opacity: 1;
        }

        span {
          i {
            font-size: 2em;
          }
        }
      }
    }
  }
  &.show-right-panel{
    right:5% !important;

  }
}


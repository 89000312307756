$table-light-text: #303e44;
$table-input-line-height: 28px;
$table-day-service-border-color: #e6e6e8;

.route-master-view {
  .container {
    padding: 0.5rem;
  }

  .tooltip-inner {
    background: inherit;
    white-space: nowrap;
    font-size: $font-size-xsm-dds;
  }

  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
    border-radius: 4px;

    .generic-panel {
      &.route-information-panel {
        flex: 1 0 auto;
      }

      &.enable-margin-left {
        margin-left: 0.5rem;
      }

      .panel-body {
        font-size: 12px;
        font-weight: 600;

        .container {
          padding-top: 0;
          .input-group {
            align-items: baseline;
            flex-wrap: nowrap;

            label {
              flex: 0 0 35%;
            }

            .ember-text-field {
              flex: 0 0 30%;
              height: 25px;
              margin-right: 1rem;
            }

            .ember-checkbox {
              flex: 0 0 25%;
              input {
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }

          h4 {
            white-space: nowrap;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 1rem;
            margin-top: 0.5rem;
          }

          .route-master-options {
            align-items: baseline;
            flex-wrap: nowrap;
            flex-direction: column;
            label {
              flex: 0 0 35%;
              white-space: nowrap;
              margin-bottom: 1rem;
            }

            .checkbox-group {
            }

            .ember-checkbox {
              flex: 0 0 25%;
            }
          }
        }
      }
    }
  }

  .tab-wrapper {
    margin-top: 0.5rem;
    border: 1px solid #ccced0;
    border-radius: 4px;

    .container {
      background: $background-lighter;
      display: flex;
      padding: 1rem;

      .days-of-service-table {
        tr {
          height: 25px;
        }

        td {
          &.max-width-1 {
            width: 80px;
          }
        }

        white-space: nowrap;
        input {
          border-radius: 2px;
          font-size: $font-size-sm-dds;
          font-weight: 500;
          line-height: 1rem;
          height: 20px;
          padding: 0;
        }

        .input-cell {
          width: 110px;
        }

        .datetimeflatpickr-wrapper {
          width: 80px;
        }
        .check-box-group {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;

          label {
            font-weight: 600;
            margin: 0 0 0 0.5rem;
            font-size: $font-size-xsm-dds;
          }
        }
        .input-group {
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          width: 100%;

          .enable-uppercase {
            text-transform: uppercase;
          }

          .vehicle-name {
            flex: 0 0 70px;
          }

          .route-length {
            flex: 0 0 60px;
          }

          .break-length {
            flex: 0 0 100px;
          }

          .address {
            flex: 0 0 125px;
            text-overflow: ellipsis;
          }

          button {
            @include button();
            margin-left: 0.5rem;
            height: 20px;
            line-height: 1rem;
            i {
              font-size: $font-size-xsm-dds;
            }
            &:hover {
              i {
                color: $link-primary;
              }
            }
          }
        }
        .config-group {
          span {
            font-size: $font-size-xsm-dds;
            font-weight: 600;
            color: $table-light-text;
            opacity: 0.5;
            &.enable-margin-left {
              margin-left: 0.5rem;
            }
          }
        }

        .spacer-0 {
          min-width: 1rem; // 16px
        }
        .spacer-1 {
          min-width: 2rem; // 32px
        }
        .spacer-2 {
          min-width: 4rem; // 62px
        }

        .table-header {
          font-size: 12px;
          font-weight: 600;
          vertical-align: bottom;
        }

        .cell-border-bottom {
          border-bottom: 1px solid $table-day-service-border-color;
        }
      }

      .zone-button-wrapper {
        margin-left: 1.5rem;
        flex: 1 0 50%;
        button {
          @include button();
          height: 25px;
          border: 1px solid #B4B4B4;
          border-radius: 2px;
          background: $button-bg-grey;
          line-height: 1.1;
          cursor: pointer;
      
          span {
            flex: auto;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .input-group {
          margin-top: 1.5rem;
          display: flex;
          flex-wrap: nowrap;
          align-items: baseline;

          label {
            font-size: $font-size-sm-dds;
            font-weight: $font-weight-bold-dds;
            flex: 0 0 25%;
            color: $dark-dds;
          }

          .ember-power-select-trigger {
            flex: 0 0 30%;
          }

        }
      }
    }
  }
}

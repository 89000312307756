.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center {
  .loading-modal-styles-overlay {
        padding: 0;
        border-radius: 1;
        z-index: zindex(action-modal);
        outline: none;
        &.ember-modal-overlay.translucent {
          background-color: rgba(38,50,56,0.2);
        }
      
      .ember-modal-dialog {
          background-color: transparent;
          box-shadow: none;
          left: 50%;
          top: 50%;

        .tooltip-modal {
          width: 438px;
          height: 240px;
          // height: 552px;
          color: white;
          background: #263238;
          border-radius: 10px;
          box-shadow: 1px 2px 6px 1px rgba(0,0,0,0.4);
      
          .tooltip-modal-header {
            width: 100%;
            height: 35px;
            background-image: linear-gradient( #334247, #171f22);
            font-size: medium;
            font-weight: 500;
            text-align: center;
            line-height: 2;
            padding-left: 6px;
            border-radius: 10px 10px 0px 0px;
      
            button {
              background-color: transparent;
              border: none;
              outline: none;
              float: right;
              padding-right: 15px;
              color: #ffffff;
            }
          }
      
          .tooltip-modal-body {
            margin: 0 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 200px;
            font-weight: 500;

            .tooltip-text {
              font-size: 16px;
              display: flex;
              justify-content: center;
              p {
                margin: 0;
                padding: 0;
              }
            }

            .spinner {
              display: flex;
              justify-content: center;
              align-content: center;
              padding: 0 5px;
            }

            .ember-progress-bar {
              padding: 0 5px;
            }
          }
        }
      }
  }
}
// --- dashboard ---

// dashboard background color
$dashboard-background-light: $background-light;
$dashboard-background-dark: black;
// maximum supported browser width in pixels
$max-dashboard-width: 5000;
$max-dashboard-height: 2000;

// tile configuration options
$tile-sizes: 100, 150, 2000;
$tile-spacings: 2, 4, 6, 8, 16;

// grid colors
$grid-color: #ddd;
$grid-background: $background-light;
$grid-cell-color: #e0e1e4;
$grid-gutter-color: $grid-background;

.tile-dashboard {
  height: calc(100% - 10px);
  width: 100%;

  padding-top: 4px;
}

.tile-dashboard-content {
  &.dashboard-mode-edit {
    background-color: $grid-cell-color;
  }

  .tile-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .grid-stack {
    .grid-stack-item {
      &.grid-stack-placeholder {
        .placeholder-content {
          /* background-color: rgb(145, 202, 255);
          opacity: 0.2;
          border: 0; */
          background-color: rgba(38, 50, 56, 0.05);
          opacity: 1;
          border: 1px #7cb342 dashed;
        }
      }

      .grid-stack-item-content {
        left: 0 !important;
        right: 0 !important;
      }

      &.ui-draggable-dragging,
      &.ui-resizable-resizing {
        > .grid-stack-item-content {
          box-shadow: 0 2px 10px rgba(38, 50, 56, 0.5);
          opacity: 1;
          border-radius: 5px;
        }
      }
    }
  }

  .ui-resizable-handle {
    opacity: 0 !important;

    &.ui-resizable-n {
      z-index: 50;
    }

    &.ui-resizable-e {
      right: 0px;
    }

    &.ui-resizable-s {
    }

    &.ui-resizable-w {
      left: 0px;
    }

    &.ui-resizable-nw {
      left: 0px;
    }

    &.ui-resizable-sw {
      left: 0px;
    }

    &.ui-resizable-se {
      right: 0px;
    }
  }

  .x-button {
    position: absolute;
    margin: 8px 12px 8px 6px;
    padding: 0;

    width: 20px;
    height: 20px;

    z-index: 20;

    color: $background-dark-dds;
    background: none;
    border: 0;

    font-size: 14px;
    font-weight: bold;

    text-align: center;
    line-height: 20px;

    cursor: pointer;

    &:hover {
      color: #000;
    }
  }

  // these only depend on tile spacing
  @each $tile-spacing in $tile-spacings {
    $tile-spacing-px: $tile-spacing * 2px;

    &.tile-spacing-#{$tile-spacing} {
      &.dashboard-mode-edit {
        // background grid
        background-image: linear-gradient(
            $grid-gutter-color $tile-spacing-px,
            transparent $tile-spacing-px
          ),
          linear-gradient(
            90deg,
            $grid-gutter-color $tile-spacing-px,
            transparent $tile-spacing-px
          );
        background-position: (-$tile-spacing-px / 2) (-$tile-spacing-px / 2);
      }

      .tile-wrapper {
        padding: 0.5 * $tile-spacing-px;

        .x-button {
          top: $tile-spacing-px/2 + $widget-border-width;
          right: $tile-spacing-px/2 + $widget-border-width - 2px;
        }
        .handles {
          z-index: 5;
          font-size: 5px;
          margin: 0 1px;
          position: absolute;
          color: $widget-drag-handle-color;
        }
        .handle-top-left {
          top: 0;
          left: 0;
        }
        .handle-top-middle {
          top: 0;
          right: calc(100% / 2 - 3px);
        }
        .handle-top-right {
          top: 0;
          right: 0;
        }
        .handle-center-left {
          left: 0;
          top: calc(50% - 3px);
        }
        .handle-center-right {
          right: 0;
          top: calc(50% - 3px);
        }
        .handle-bottom-left {
          bottom: 0;
          left: 0;
        }
        .handle-bottom-middle {
          bottom: 0;
          right: calc(100% / 2 - 3px);
        }
        .handle-bottom-right {
          bottom: 0;
          right: 0;
        }
      }

      .grid-stack {
        .grid-stack-item {
          // for larger tile size, need less CSS rules to cover same space
          .placeholder-content {
            top: $tile-spacing-px/2 !important;
            left: $tile-spacing-px/2 !important;
            right: $tile-spacing-px/2 !important;
            bottom: $tile-spacing-px/2 !important;
          }
        }
      }
    }
  }

  // these only depend on tile-size
  @each $tile-size in $tile-sizes {
    $tile-size-px: $tile-size * 1px;

    &.tile-size-#{$tile-size} {
      &.dashboard-mode-edit {
        background-size: $tile-size-px $tile-size-px;
      }

      @for $i from 1 through floor($max-dashboard-width / $tile-size) {
        &.cols-#{$i} {
          width: $i * $tile-size-px !important;
        }
      }

      @for $i from 1 through floor($max-dashboard-height / $tile-size) {
        &.rows-#{$i} {
          height: $i * $tile-size-px !important;
        }
      }

      .grid-stack {
        .grid-stack-item {
          // for larger tile size, need less CSS rules to cover same space
          @for $i from 1 through floor($max-dashboard-width / $tile-size) {
            &[data-gs-width="#{$i}"] {
              width: $i * $tile-size-px;
              min-width: $tile-size-px !important;
            }
            &[data-gs-x="#{$i}"] {
              left: $i * $tile-size-px;
            }
          }

          @for $i from 1 through floor($max-dashboard-height / $tile-size) {
            &[data-gs-height="#{$i}"] {
              height: $i * $tile-size-px;
              min-height: $tile-size-px !important;
            }
          }
        }
      }
    }
  }
}

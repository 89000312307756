html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

// Ember does not provide a way to place a class on the application view,
// so this ensures that sub-view can set `height: 100%` correctly
body > .ember-view {
  height: 100%;
}

.flex-grow-1 {
  flex-grow: 1;
}

.ember-power-select-trigger[aria-disabled=true] {
  background: transparent !important; // refactor css and prevent using important
}

.ember-basic-dropdown-content {
  -webkit-touch-callout: none;                /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;             /* prevent webkit from resizing text to fit */
  -webkit-user-select: none;
  user-select: none;
  z-index: zindex(dropdown); // global dropdown z-index
}

// Overrides for ember cli addons:
.ember-power-select-options {
  font-size: $widget-input-font-size;

  .ember-power-select-option[aria-current="true"] {
    background-color: dds("700");
  }
}

// chrome browser automatically renders an outline thats not pretty to look at
input,textarea {
  outline: none;
}

button {
  outline: none !important;
}
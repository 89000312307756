// $calendarBackground: $background-dark-dds;
// $calendarBorderColor: rgba(#484848, 0.2);

// $monthForeground: #fff;
// $monthBackground: $background-dark-dds;

// $weekdaysBackground: $background-dark-dds;

$dayForeground: #fff;
$dayHoverBackground: dds("500");

// $todayColor: #bbb;

$selectedDayBackground: dds("500");
$selectedDayForeground: #fff;

.flatpickr-calendar {

  // .flatpickr-months {
  //   .flatpickr-monthDropdown-months {}
  // }

  .flatpickr-day {

    &.selected {
      background: $selectedDayBackground;
      border-color: $selectedDayBackground;
    }

    &.today {
      border-color: $selectedDayBackground;
    }

    &.flatpickr-disabled {
      cursor: not-allowed;
      border-color: transparent;

      &:hover {
        background: none;
      }
    }

    &:hover {
      background: $dayHoverBackground;
      color: $dayForeground;
    }

  }

}

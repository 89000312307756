// timeline picker layout
$date-picker-width: 250; // must be 7x + 12, e.g. 292, 299, 306, etc.

.timeline-date-picker {
  .timeline-date-picker-input {
    width: 100%;
    color: $background-dark-dds;
    text-align: center;
    font-size: $widget-body-font-size;
    font-weight: 600;
    padding: 0px 5px;

    border: 1px solid #979797;
    border-radius: 2px;
    padding: 2px;

    &:focus {
      border: 2px solid $dds-primary;
      outline: none;
    }

    &.is-valid {
      // background-color: rgba(darkseagreen, 0.2);
    }

    &.is-invalid {
      border: 2px solid $danger-color;
    }

    &.is-dirty {
      font-weight: bold;
    }
  }
}

// rendered in wormhole
.timeline-date-picker-dropdown {
  .shortcut-buttons {
    display: flex;
    margin: 2px 1px 0 1px;

    .btn-shortcut {
      flex: 1 0 80px;
      margin: 0 1px;
    }
  }

  .btn-done {
    margin: 0 2px 2px 2px;
    display: block;
  }

  .timeline-date-picker-calendar {
    $cell-size: ($date-picker-width - 12) / 7;
    $cal-day-radius: ($cell-size / 2) + px;
    @include ember-power-calendar($cell-size: $cell-size * 1px);

    font-size: 12px;
    padding: 5px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, .2);
    border-radius: 3px;

    .ember-power-calendar-nav {
      .ember-power-calendar-nav-title {
        color: $background-dark-dds;
      }
      .ember-power-calendar-nav-control {
        font-size: 16px;
        outline: none;
        color: $dds-primary;

        &:focus {
          transform: none;
        }
      }
    }

    .ember-power-calendar-days {
      .ember-power-calendar-weekdays {
        .ember-power-calendar-weekday {
          color: #92989B;
        }
      }

      .ember-power-calendar-day-grid {

        .ember-power-calendar-row {
          justify-content: end;
        }

        .ember-power-calendar-day {
          outline: none;
          box-shadow: none;
          background-color: white;
        }
        .ember-power-calendar-day--selected {
          background-color: $dds-primary;
          color: white;
          font-weight: 400;
        }
        .ember-power-calendar-day--current-month {
          color: $background-dark-dds;
        }

        .ember-power-calendar-day--range-start {
          border-radius: $cal-day-radius 0px 0px $cal-day-radius;
        }

        .ember-power-calendar-day--range-end {
          border-radius: 0px $cal-day-radius $cal-day-radius 0px;
        }

        .ember-power-calendar-day--selected.ember-power-calendar-day--current-month {
          color: white;
        }

        .ember-power-calendar-day--range-end.ember-power-calendar-day--range-start {
          background-color: $dds-primary;
          color: white;
          border-radius: $cal-day-radius;
        }

        // .ember-power-calendar-day--range-start.ember-power-calendar-day--selected {
        //   border-radius: $cal-day-radius;
        // }

      }

    }
  }
}

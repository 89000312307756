.time-picker-wrapper {

  .ember-timepicker {
    width: 100%;
    color: $background-dark-dds;
    text-align: center;
    font-size: $widget-body-font-size;
    font-weight: 600;
    padding: 0px 5px;

    border: 1px solid #979797;
    border-radius: 2px;
    padding: 2px;

    &:focus {
      border: 2px solid $dds-primary;
      outline: none;
    }

    &.is-valid {
      background-color: rgba(darkseagreen, 0.2);
    }

    &.is-invalid {
      background-color: rgba(lightcoral, 0.2);
    }

    &.is-dirty {
      font-weight: bold;
    }
  }
}

.is-invalid {
  .ember-timepicker {
    border: 2px solid $danger-color;
    background-color: rgba(240, 128, 128, 0.2);
  }
}

.ui-timepicker-container {
  border: none;
  border-radius: 2px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, .2);

  .ui-timepicker {
    font-size: $widget-body-font-size;
    font-family: $ui-font;
    font-weight: 600;
    .ui-timepicker-viewport {
      .ui-menu-item {
        .ui-state-hover {
          font-weight: 600;
          background-color: $table-row-selected-color;
          border: 1px solid transparent;
        }
        &:hover {
          background-color: $table-row-selected-color;
        }
      }
    }
  }
}

.timeline-time-picker-dropdown {
  width: $timeline-time-picker-width * 1px;

  .shortcut-buttons {
    display: flex;
    margin: 2px 1px 0 1px;

    .btn-shortcut {
      flex: 1 0 80px;
      margin: 0 1px;
    }
  }

  .btn-done {
    margin: 0 2px 2px 2px;
    display: block;
  }
}

$system-config-right-panel-header-color: rgba(199,199,199,0.5);

.right-panel {
  .g-side-drawer-header {
    padding: 10px 20px 10px 20px;
    background-color: $light-dds;
    @include box-shadow(0, 2px, 4px, $system-config-right-panel-header-color);
  
      .header-title {
        font-size: $g-sidedrawer-title-font-size;
        font-weight: 600;
        white-space: nowrap;
        margin: 0;
      }
  
      .header-buttons {
        flex-grow: 0;
        flex-shrink: 0;
    
        button {
          @include button();
          height: 26px;
          width: 75px;
          border: 1px solid #B4B4B4;
          border-radius: 2px;
          background: $button-bg-grey;
          margin-left: 20px;
          line-height: 1.1;
          cursor: pointer;
      
          span {
            flex: auto;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
  
    }
}


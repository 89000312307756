$panel-bg-color: $background-lighter;
$panel-border-color: #CCCED0;

.generic-panel {
  background-color: $panel-bg-color;
  border: 1px solid $panel-border-color;

  padding: 8px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  .panel-header {
    display: flex;
    padding: 4px;
    cursor: default;

    &.is-collapsible {
      cursor: pointer;
    }

    i {
      margin-right: 0.5rem;
    }
  }

  .panel-header-caret {
    flex: 0 0 30px;
    text-align: center;
    line-height: 36px;
  }

  .panel-title {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .panel-body {}

  &.is-collapsed {
    .panel-body {
      display: none;
    }
  }

  p {
    margin: 0 16px 18px 16px;
    font-size: 12px;
  }

  .panel-loader {
    display: flex;
    justify-content: center;
    align-items: center;

    // hack
    padding-top: 20px;
  }

  .panel-form {
    padding: 0 16px;
    border: 0;

    margin-bottom: 12px;
  }
}


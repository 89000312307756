.vertical-panel {
  display: flex;
  flex-direction: column;
  min-height: 176px;
  border: 1px solid #ccced0;
  border-radius: 4px;
  background-color: #f5f5f7;
  padding-top: 4px;
  font-weight: 100;

  .vertical-menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .item {
      cursor: pointer;
      height: 28px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      padding: 2px 6px 2px 6px;

      &.disabled {
        cursor: not-allowed;
        color: $icon-light-color;
        border-color: $button-outline;
      }

      &.is-selected {
        height: 24px;
        background-color: #deebcf;
      }
    }
  }
}

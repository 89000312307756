.dashboard-save-as {
  .dashboard-save-as-body {
    padding: 10px 30px 10px 39px;

    input {
      width: 100%;
    }
  }

  .dashboard-save-as-controls {
    padding: 10px 30px 0 39px;
  }
}

// Primary Color Variations
@function dds($key: "600") {
  @return map-get($dds-primary-variants, $key);
}

// Grey Color Variation
@function dds-greys($key: "600") {
  @return map-get($dds-grey-variants, $key);
}

// Z Index
// z-index labels
// action-modal: 9001,
// modal: 9000,
// overlay: 8000,
// dropdown: 7000,
// header: 6000,
// footer: 5000
@function zindex($label) {
  @return map-get($z-index, $label);
}

.bulk-trip-transfer-side-drawer{
  height: inherit;
  overflow-y: auto;

  .bulk-trip-transfer-layout {
    .g-side-drawer-body {
      margin-top: 8px;
      background-color: white;

      .g-side-drawer-panel.is-collapsible.is-expanded, .g-side-drawer-panel.is-initial {
        .g-side-drawer-panel-body {
          display: inline-block;
          flex-direction: column;
          padding: 0px 0 0px 0px;
          color: #303E44;

          .view-impact-table-wrapper {
            overflow: auto;
            flex-basis: 200px;
            flex-grow: 1;
            flex-shrink: 1;
            width: calc(100% - 0px);
            font-size: 12px;
            background-color: #fff;

            .ember-light-table {
              overflow: visible;
              font-feature-settings: "tnum";
              width: fit-content;
            }
            .yellow-table-row{
              background-color: #F0ED92 !important;
            }
          }

          .g-side-drawer-panel-selector {
            float: left;
            width: 43%;
            h5 {
              width: 66px;
              margin: 0px 14px 0 15px;
            }

            .dropdown {
              .ember-power-select-trigger {
                width: 155px;
              }
            }
          }

          input#override {
            margin-left: 10px;
          }

          label {
            margin: 5px 0px 7px 9px;
            font-size: 14px;
            font-weight: bold;
          }
          input[type="checkbox"] {
            vertical-align: bottom;
            position: relative;
            top: -4px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
.bulk-transfer{
  hr{
    width: 94%;
    &:last-child{
      display: none;
    }
  }
}

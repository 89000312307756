.metrics-column-widget {
  font-family: $ui-font;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .metrics-column-header {

    .ember-power-select-trigger {
      flex-grow: 1;
      flex-shrink: 0;
      margin: 0px 5px 5px 2px;
      font-size: 13px;
    }

    .metrics-column-button {
      display: inline-block;
      padding: 0 4px 0 4px;
      border: none;
      color: $icon-light-color;
      background-color: transparent;
      opacity: 0.5;
      cursor: pointer;
      outline: none;

      &:hover {
        color: $icon-light-highlighted-color;
        transition: color 0.2s;
        opacity: 1;
      }
    }

  }

  .metrics-metadata {
    font-size: 13px;


    table {
      padding-top: 5px;
      width: 100%;
      border-collapse: collapse;
      background-color: rgba(230, 231, 235, .4);

      tr {
        th {
          font-weight: 600;
          padding: 2px 5px;
          border-bottom: 2px solid rgba(230, 231, 235, .4);
          width: 25%;
          background-color: transparent;
          color: $font-color;

          &:first-child {
            padding-left: 10px;
          }
        }

        td {
          font-weight: 400;
          padding: 2px 5px;
          border-top: 2px solid rgba(230, 231, 235, .4);
          border-bottom: 5px solid rgba(230, 231, 235, .4);
          width: 25%;
          color: $font-color;

          &:first-child {
            padding-left: 10px;
          }
        }
      }
    }
  }

  .metric-column-widget-body {
    // must set `flex-basis` or else light table will have incorrect height
    flex-basis: 300px;
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - #{$widget-border-width});
    overflow-x: auto;

    font-size: $widget-body-font-size;
    background-color: $table-background-color;


    .ember-light-table {
      height: 100%;

      .lt-head-wrap {
        background-color: $table-header-background-color;
        /* box-shadow: 0px 0px 5px 0px $grid-color; */
        box-shadow: 0px 1px 5px rgba(38, 50, 56, 0.2);
        z-index: 1;
        margin-bottom: 3px;
      }

      .lt-head {
        /* background-color: $table-header-background-color; */
        background-color: rgba(230, 231, 235, .4);
        width: 100%;

        th {
          /* padding: 2px 5px 2px 5px; */ // We just want the top cell to have padding
          padding: 5px;
          font-weight: 500;
          color: $font-color;
          opacity: 0.7;
          &:first-child {
            padding-left: 10px;
          }
        }

        // draw line for resize handle
        .lt-column-resizer {
          background: linear-gradient(to right,
                                      transparent 0%,
                                      transparent calc(50% - 1px),
                                      #ccc calc(50% - 1px),
                                      #ccc calc(50% + 0px),
                                      transparent calc(50% + 0px),
                                      transparent 100%) !important;
                                      height: 70%;
                                      margin-top: 5px;

        }
      }

      .table-base-cell {
        padding-left: 0px;
        &:first-child {
          padding-left: 5px;
        }
      }

      .lt-row {
        &:nth-child(2n) {
          background-color: $table-row-alt-background-color;
        }

        &.is-selected {
          background-color: $table-row-selected-color !important;
        }

        &.lt-is-loading {
          text-align: center;
          background-color: transparent;
        }

        &.lt-no-data {
          background-color: transparent;
        }


      }

      .lt-cell {
        padding: 2px 5px 2px 5px; // same here: we just want the top cell to have padding

        .lt-cell {
          padding: 0 0 0 0;
        }

      }

      .lt-body {
        color: $table-body-text;
        font-weight: 300;
      }

      .is-sorted {
        .lt-sort-icon {
          visibility: visible;
        }
      }

      .lt-sort-icon {
        float: none;
        margin-left: auto;
        margin-right: 0;
        padding-left: 5px;
        // visibility: hidden;
        line-height: 20px;
      }
    }

    .cell-text-extension {
       background-color: transparent;
       border: 0;
       padding: 0;
       color: #657175;
       font-weight: 300
    }
  }

}

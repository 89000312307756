// Capable of Adding different types of fonts
// however we should limit the amount of custom fonts and carefully choose at most 2-3 different custom fonts
// also we should use a fall back font for the user based on system fonts
@mixin font-face($name) {
  @font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/AvenirNext-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/AvenirNext-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
   }
  @font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/AvenirNext-DemiBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/AvenirNext-Bold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  // @font-face {
  //   font-family: 'MyWebFont';
  //   src: url('webfont.eot'); /* IE9 Compat Modes */
  //   src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //        url('webfont.woff2') format('woff2'), /* Super Modern Browsers */
  //        url('webfont.woff') format('woff'), /* Pretty Modern Browsers */
  //        url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
  //        url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  // }
}

@include font-face('AvenirNext');

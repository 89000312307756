.operation-list {
  display: flex;
  flex-direction: column;

  .operation-summary {
      height: auto;
      width: 189px;
      color: #303E44;
      font-family: "Avenir Next";
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      padding: 16px 7px 0 4px;
      margin-bottom: 1px;
  }

}

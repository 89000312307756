.add-break-side-drawer{
    height: inherit;
    overflow-y: auto;
  
    .add-break-layout {
      .g-side-drawer-body {
        margin-top: 8px;
        background-color: white;
  
        .g-side-drawer-panel {

          .g-side-drawer-panel-body {
            
            .view-impact-table-wrapper {
              width: 563px;
              overflow: auto;
  
              .ember-light-table {
                width: 850px;
              }
            }
  
            .g-side-drawer-panel-selector {
             h5 {
                width: 66px;
                margin: 0px 14px 0 15px;
              }
  
              .dropdown {
                .ember-power-select-trigger {
                  width: 155px;
                }
              }
            }
  
            input#override {
              margin-left: 10px;
            }
  
            label {
              margin: 5px 0px 7px 9px;
              font-size: 14px;
              font-weight: bold;
            }
            input[type="checkbox"] {
              vertical-align: bottom;
              position: relative;
              top: -4px;
              overflow: hidden;
            }
          }
        }
      }
    }  
    .locations-input{

    }
    .g-side-drawer-panel-form{
        position: relative;
        width: 73%;
    }
    
    .g-side-drawer-body {
      background-color: white;
    }
    
    .toggle-switch {
      height: 39px
    }

    .error-message {
      color: #E02020;
      font-weight: bold;

      &.padded {
        padding: 0 0.75em;
      }
    }
  }
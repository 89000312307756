$ring-color: rgb(124, 179, 66);
$border-size: 0.5em;

.ring-window {

  display: flex;
  overflow: hidden;
  justify-content: center;
  align-content: center;
  height: inherit;
  width: 70%;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  z-index: 10;

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 7em;
    height: 7em;
  }
  .loader {
    font-size: 10px;
    position: relative;
    border-top: $border-size solid lightgray;
    border-right: $border-size solid lightgray;
    border-bottom: $border-size solid lightgray;
    border-left: $border-size solid $ring-color;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    display: flex;
    align-self: center;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

}


